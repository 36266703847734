import sharedConstants from '@/shared/constants.json'

const { PARTICIPATION_STATUS } = sharedConstants

const participationStatusTexts = {
  [PARTICIPATION_STATUS.NOT_STARTED]: 'No comenzó',
  [PARTICIPATION_STATUS.COMPLETE]: 'Finalizado',
  [PARTICIPATION_STATUS.IN_PROGRESS]: 'En curso',
  [PARTICIPATION_STATUS.DISQUALIFIED]: 'Descalificado'
}

export const participationStatusText = (participationStatus) => participationStatusTexts[participationStatus]

export const formatText = (text, length) => `${text.substring(0, length)}${text.length > length ? '...' : ''}`

export const formatTaskTitle = (title) => formatText(title, 140)

export const taskResponseToArray = (task, response) => {
  let result = ''

  if (task.taskType === sharedConstants.TASK_TYPE.OPEN_QUESTION) {
    if (response.responseMediaFiles && response.responseMediaFiles.length > 0) {
      response.responseMediaFiles.forEach((responseMediaFile) => {
        const description = responseMediaFile.description.replace(/\r\n/g, '. ')
        result += `[${responseMediaFile.mediaFile.publicId}]`

        if (description) {
          result += `: ${description}`
        }

        result += '\r\n'
      })
    } else {
      result = response.value.replace(/\r\n/g, '. ')
    }
  } else if (task.taskType === sharedConstants.TASK_TYPE.RATING) {
    result = []
    task.options.forEach((option) => {
      const responseOptionValue = response.optionValues.find((optionValue) => optionValue.option === option.id)

      const value = responseOptionValue === undefined ? 'No respondió' : responseOptionValue.integerValue

      result.push(`${option.description}: ${value}`)
    })
  } else {
    const options = task.options.filter((option) => response.options.includes(option.identifier))

    result = options.map((option) => option.description)

    if (response.value) {
      result.push(`${task.customOptionText}: ${response.value}`)
    }
  }

  return result
}
