<template>
  <div class="centered-image-and-text-module">
    <div class="image-container">
      <img
        :src="require('@/assets/' + image)"
      >
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="text">
      <slot />
    </div>
    <div
      v-if="buttonText"
      class="cta"
    >
      <template v-if="typeof(buttonLink) === 'object'">
        <router-link
          v-slot="{ href, navigate }"
          :to="buttonLink"
        >
          <a
            class="button"
            :href="href"
            @click="navigate"
          >
            {{ buttonText }}
          </a>
        </router-link>
      </template>
      <template v-else>
        <a
          class="button"
          :href="buttonLink"
        >
          {{ buttonText }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CenteredImageAndTextModule',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonLink: {
      type: [String, Object],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.centered-image-and-text-module {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  user-select: none;

  @include respond(mobile) {
    padding: 38px 20px 0;
  }

  .image-container {
    display: block;
    margin-bottom: 38px;

    @include respond(mobile) {
      height: auto;
      margin-bottom: 10px;
    }

    img {
      width: 390px;

      @include respond(mobile) {
        width: 250px;
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: $black;
    margin-bottom: 15px;

    @include respond(mobile) {
      font-size: 22px;
    }
  }

  .text {
    max-width: 490px;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    color: $black;
    margin-bottom: 74px;

    @include respond(mobile) {
      max-width: unset;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 60px;
    }
  }

  .cta {
    .button {
      color: #fff;
      background: #0a6cff;
      border: none;
      height: 44px;
      line-height: 44px;
      border-radius: 22px;
      font-weight: 600;
      font-size: 18px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all .2s ease-in-out;
      outline: none;
      padding-left: 44px;
      padding-right: 44px;

      &:hover {
        background-color: #0254d0;
      }
    }
  }
}
</style>
