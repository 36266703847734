<template>
  <div class="task-container">
    <div class="activity-title">
      {{ task.activity.name }}
    </div>
    <div class="task-title">
      {{ taskTitle }}
    </div>
    <template v-if="task.taskResponses.length > 0">
      <div class="responses-container">
        <div class="response-title">
          Últimas respuestas
        </div>
        <response
          v-for="(response) in task.taskResponses.slice(0, 3)"
          :key="response.id"
          :response="response"
          :task-data="task"
          :display-comments="false"
        />
      </div>
      <div class="task-actions">
        <router-link
          v-slot="{ href, navigate }"
          :to="taskInteractionsLink"
        >
          <a
            :href="href"
            class="response-button"
            @click="navigate"
          >
            Ver respuestas{{ isParticipant || currentProjectStaffUserIsObserver ? ' y comentar' : '' }}
          </a>
        </router-link>
      </div>
    </template>
    <div
      v-else
      class="task-actions"
    >
      Aún no hay respuestas para esta Actividad
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatTaskTitle } from '@/format'

import Response from '@/components/interactions/Response.vue'

export default {
  name: 'InteractionsTask',
  components: {
    Response
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('Projects', ['currentProjectStaffUserIsObserver']),
    taskInteractionsLink() {
      return {
        name: 'task-interactions',
        params: {
          projectIdentifier: this.$route.params.projectIdentifier,
          taskIdentifier: this.task.identifier
        }
      }
    },

    taskTitle() {
      return formatTaskTitle(this.task.title)
    }
  }
}
</script>

<style lang="scss" scoped>
.response-title + .response-container {
  border-top-width: 0;
}

.response-container {
  padding-left: 14px;
  padding-right: 14px;

  ::v-deep .avatar {
    margin-right: 10px;
  }
}

.task-container {
  margin-bottom: 24px;
  border: 1px solid $gray-light;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.24);
  border-radius: 6px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .activity-title {
    padding: 10px 14px;
    padding-bottom: 0px;
    text-align: left;
    font-size: 16px;
    color: #333333;
  }

  .task-title {
    margin-bottom: 0px;
    padding: 14px;
    padding-top: 8px;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    border-bottom: 1px solid $gray-light;
  }

  .responses-container {
    .response-title {
      padding: 15px;
      padding-bottom: 0px;
      margin-bottom: 5px;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }
  }

  .task-actions {
    padding: 18px;

    a {
      height: 44px;
      padding: 10px 10px;
      background: #FFFFFF;
      border: 1px solid $blue;
      font-weight: 600;
      font-size: 14px;
      border-radius: 50px;
      line-height: 44px;
      box-sizing: border-box;
      cursor: pointer;
      transition: $buttonBackgroundTransition;
      outline: none;
      padding-left: 44px;
      padding-right: 44px;

      &:hover {
        color: #fff;
        background: $blue;
      }
    }
  }
}
</style>
