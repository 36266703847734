<template>
  <div>
    <navigation-bar
      :background="'white'"
      :link="{ name: navigationToProjectName, params: { identifier: projectIdentifier } }"
      label="Proyecto"
    />
    <div class="container is-widescreen page-bottom-padding">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column">
            <b-select
              v-if="showFilter && (selectedActivityIdentifier || filteredTasks.length > 0) && activities.length > 1"
              v-model="selectedActivityIdentifier"
              class="activity-select"
            >
              <option
                :key="null"
                :value="null"
              >
                Mostrar todas las actividades
              </option>
              <option
                v-for="activity in activities"
                :key="activity.identifier"
                :value="activity.identifier"
              >
                {{ activity.name }}
              </option>
            </b-select>
            <centered-image-and-text-module
              v-if="showNoInteractions"
              image="interactions.png"
              :title="`Sos el primero en responder${selectedActivityIdentifier ? ' esta Actividad' : ''}`"
            >
              ¡Volvé más tarde para interactuar con otros participantes!
            </centered-image-and-text-module>
            <task
              v-for="(task) in filteredTasks"
              v-else
              :key="task.id"
              :task="task"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CenteredImageAndTextModule from '@/components/CenteredImageAndTextModule.vue'
import NavigationBar from '@/components/NavigationBar'
import Task from '@/components/interactions/Task.vue'

export default {
  name: 'Project',
  components: { CenteredImageAndTextModule, NavigationBar, Task },
  props: {
    activityIdentifier: {
      type: String,
      default: null
    },
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedActivityIdentifier: null
    }
  },
  computed: {
    ...mapState('interactionsInteractions', ['taskListData']),
    ...mapState('Users', ['userData']),

    projectIdentifier() {
      return this.$route.params.projectIdentifier
    },

    navigationToProjectName() {
      return this.userData.is_participant ? 'project-home' : 'project-dashboard-summary'
    },

    activities() {
      const distinctActivities = []

      this.taskListData.forEach((task) => {
        const existingActivity = distinctActivities.find((activity) => activity.identifier === task.activity.identifier)
        if (!existingActivity) {
          distinctActivities.push({ identifier: task.activity.identifier, name: task.activity.name })
        }
      })

      return distinctActivities
    },

    filteredTasks() {
      // Tasks won't be filtered if the user is a Staff user
      // If the user is a Participant, only tasks with more than one reply will be displayed
      // If an activity filter is active, they will be additionally filtered by activity
      return this.taskListData.filter(
        (task) => (
          (task.taskResponses.length > 1 || !this.isParticipant)
            && (!this.selectedActivityIdentifier || task.activity.identifier === this.selectedActivityIdentifier)
        )
      )
    },

    showNoInteractions() {
      return this.isParticipant && this.filteredTasks.length === 0
    }
  },
  created() {
    if (this.activityIdentifier) {
      this.selectedActivityIdentifier = this.activityIdentifier
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .activity-select {
  margin-bottom: 16px;

  .select {
    width: 100%;

    select {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0250765);
      border-radius: 5px;

      option {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}
</style>
